import AccessManager from '../../clients/abdaAccessManager'

const resourceName = 'accountprofile'

const GetAll = () => AccessManager.get(resourceName)
const GetById = id => AccessManager.get(`${resourceName}/${id}`)
const GetByAccountId = accountId => AccessManager.get(`${resourceName}/account/${accountId}`)

const Create = data => AccessManager.post(resourceName, data)

const Update = data => AccessManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => AccessManager.delete(`${resourceName}/${id}`)

const AccountProfileRepository = {
  GetAll,
  GetById,
  GetByAccountId,
  Create,
  Update,
  Delete
}

export default AccountProfileRepository
