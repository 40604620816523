import PermissionRepository from '@/shared/http/repositories/access/permission'
import AccountRepository from '@/shared/http/repositories/access/account'
import ProfileRepository from '@/shared/http/repositories/access/profile'
import AccountProfileRepository from '@/shared/http/repositories/access/account-profile'
import GroupRepository from '@/shared/http/repositories/access/group'
import GroupProfileRepository from '@/shared/http/repositories/access/group-profile'
import PermissionMixin from '@/store/modules/permission/mixin'
import PermissionModel from '@/shared/models/permission'
import PermissionTypeEnum from '@/shared/enums/permissionType'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Roles from '@/shared/mixins/hasRoles'
import { actionTypes as PermissionActions } from '@/store/modules/permission/actions'
import { toast, loading } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb
  },

  mixins: [PermissionMixin, Roles],

  data: () => ({
    permission: new PermissionModel(),
    permissionTypeUser: false,
    permissionTypeGroup: false,
    availablePermission: null,
    usedPermission: null,
    profiles: [],
    permissionTypes: Object.values(PermissionTypeEnum),
    accounts: [],
    groups: [],
    availablePermissions: []
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Configurar Usuários',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.onLoad()
  },

  methods: {
    onLoad() {
      this.getAllProfiles()
      this.permission.permissionType = 'GRP'
      this.onChangePermissionType(this.permission.permissionType)
    },

    clearPermissions() {
      this.availablePermissions = []
      this.permission.permissionUsed = []
    },

    getAllAccounts() {
      this.clearPermissions()
      AccountRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.accounts = res.data.data
          return Promise.resolve()
        })
        .catch(() => {
          toast.error('Erro ao carregar contas', 'ERRO')
        })
    },

    getAllGroups() {
      this.clearPermissions()
      GroupRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.groups = res.data.data
          return Promise.resolve()
        })
        .catch(() => {
          toast.error('Erro ao carregar grupos', 'ERRO')
        })
    },

    getAllProfiles() {
      ProfileRepository.GetAll()
      .then(res => {
        if (!IsSuccessCode(res)) return Promise.reject()

        this.profiles = res.data.data
        return Promise.resolve()
      })
      .catch(() => {
        toast.error('Erro ao carregar perfis', 'ERRO')
      })
    },

    onChangePermissionType (e) {
      this.clearPermissions()
      switch (e) {
        case 'GRP': {
          this.permission.groupId = null
          this.getAllGroups()
          this.permissionTypeGroup = true
          this.permissionTypeUser = false
          break
        }
        case 'USR': {
          this.permission.accountId = null
          this.getAllAccounts()
          this.permissionTypeUser = true
          this.permissionTypeGroup = false
          break
        }
        default : {
          this.permissionTypeUser = false
          this.permissionTypeGroup = false
          this.permission.accountId = null
          this.permission.groupId = null
          break
        }
      }
    },

    onReloadPermission () {
      this[PermissionActions.GET_ALL_PERMISSIONS]()
        .then(() => {})
    },

    create() {
      loading.push()
      this.permission.permissionUsed = this.permission.permissionUsed.map(x => x.id)
      PermissionRepository.Create(this.permission)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          
          loading.pop()
          toast.success('Permissão adicionada!', 'SUCESSO')
          this.onLoad()
          Promise.resolve()
          return this.onReloadPermission()
        })
        .catch(() => {
          loading.pop()
          this.clearPermissions()
          toast.error('Erro ao adicionar permissão', 'Erro')
        })
      },

    onChangeGroup (groupId) {
      this.clearPermissions()
      if (groupId) {
        loading.push()
        GroupProfileRepository.GetByGroupId(groupId)
          .then((res) => {
            if (!IsSuccessCode(res)) return Promise.reject()

            const groupProfile = res.data.data
            const profileList = this.profiles

            if (groupProfile && groupProfile.length > 0) {
              for (const x in groupProfile) {
                for (const i in profileList) {
                  if (profileList[i].id === groupProfile[x].profileId) {
                    this.permission.permissionUsed.push({ 'name': profileList[i].name, 'id': profileList[i].id })
                    profileList.splice(i, 1)
                  }
                }
              }
              this.availablePermissions = profileList
              this.getAllProfiles()
            } else {
              this.availablePermissions = this.profiles
            }

            loading.pop()
            return Promise.resolve()
          })
          .catch(() => {
            loading.pop()
            toast.error('Erro ao definir permissões de grupo', 'Erro')
          })
      }
    },

    onChangeAccount (accountId) {
      this.clearPermissions()
      if (accountId) {
        loading.push()
        AccountProfileRepository.GetByAccountId(accountId)
          .then((res) => {
            if (!IsSuccessCode(res)) return Promise.reject()

            const accountProfile = res.data.data
            const profileList = this.profiles

            if (accountProfile && accountProfile.length > 0) {
              for (const x in accountProfile) {
                for (const i in profileList) {
                  if (profileList[i].id === accountProfile[x].profileId) {
                    this.permission.permissionUsed.push({ 'name': profileList[i].name, 'id': profileList[i].id })
                    profileList.splice(i, 1)
                  }
                }
              }
              this.availablePermissions = profileList
              this.getAllProfiles()
            } else {
              this.availablePermissions = this.profiles
            }

            loading.pop()
            return Promise.resolve()
          })
          .catch(() => {
            loading.pop()
            toast.error('Erro ao definir permissões de conta', 'Erro')
          })
      }
    },

    addPermissions () {
      let selected = this.availablePermission
      let options = this.availablePermissions

      if (selected) {
        for (let i in options) {
          if (options[i].id === selected) {
            this.permission.permissionUsed.push(options[i])
            this.availablePermissions.splice(i, 1)
            return
          }
        }
      }
    },

    removePermissions () {
      let selected = this.usedPermission
      let options = this.permission.permissionUsed

      if (selected) {
        for (let i in options) {
          if (options[i].id === selected) {
            this.availablePermissions.push(options[i])
            this.permission.permissionUsed.splice(i, 1)
            return
          }
        }
      }
    },

    backTo() { this.$router.go(-1) }
  }
}
