import AccessManager from '../../clients/abdaAccessManager'

const resourceName = 'profile'

const GetAll = () => AccessManager.get(resourceName)
const GetById = id => AccessManager.get(`${resourceName}/${id}`)


const ProfileRepository = {
  GetAll,
  GetById
}

export default ProfileRepository
