import AccessManager from '../../clients/abdaAccessManager'

const resourceName = 'group'

const GetAll = pagination => AccessManager.get(resourceName, { params: pagination })
const GetById = id => AccessManager.get(`${resourceName}/${id}`)

const Create = data => AccessManager.post(resourceName, data)

const Update = data => AccessManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => AccessManager.delete(`${resourceName}/${id}`)

const GroupRepository = {
  GetAll,
  GetById,
  Create,
  Update,
  Delete
}

export default GroupRepository
