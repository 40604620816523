import AccessManager from '../../clients/abdaAccessManager'

const resourceName = 'groupprofile'

const GetAll = () => AccessManager.get(resourceName)
const GetById = id => AccessManager.get(`${resourceName}/${id}`)
const GetByGroupId = groupId => AccessManager.get(`${resourceName}/group/${groupId}`)


const Create = data => AccessManager.post(resourceName, data)

const Update = data => AccessManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => AccessManager.delete(`${resourceName}/${id}`)

const GroupProfileRepository = {
  GetAll,
  GetById,
  GetByGroupId,
  Create,
  Update,
  Delete
}

export default GroupProfileRepository
