import GuidUtils from '@/shared/utils/guid'

export default class Permission {
  constructor({
    id,
    permissionType,
    groupId,
    accountId,
    permissionUsed
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.permissionType = permissionType,
    this.groupId = groupId,
    this.accountId = accountId,
    this.permissionUsed = permissionUsed
  }
}
